import React, {useState} from 'react';
import {
    combinationOfRowsPricing,
    mainRowPrices,
    transportRowPrices,
} from "./pricing-data";

import PriceItem from './PriceItem';

import OpenHoursBox from "../info/OpenHoursBox";
import ReservationScheduleBox from "./ReservationScheduleBox";

function ServicesPricing(props) {
    return (
        <div>
            <div className="about-wrapper pb--120 pt--120 bg_color--3">
                <div className="container">
                    <div className="row row--20">

                        <div className="col-12 col-lg-8">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h3 className="title">Price list of views</h3>
                                    <p className="description mb-0 mt-0" style={{fontSize: "0.925rem"}}>
                                        Please note that <strong>reservation is required </strong> for visiting the mine at the telephone numbers 051 312 739 or 070-700-484 or info@visitlitija.si. The price list is valid from February 1, 2024.
                                    </p>
                                </div>

                                <h4 className="mt--30 mb--0">EXPORT PIT</h4>
                                <div className="section-title mt--0 mb--30">
                                    <p className="description" style={{fontSize: "0.925rem", marginBottom: 0}}>
                                        For children up to the age of 3 (except for guiding with a dwarf), tour guides, journalists and group companions and disabled people, the visit is free.
                                    </p>
                                    <p className="description" style={{fontSize: "0.925rem", marginBottom: 0}}>
                                        We organize tours for a minimum of 4 people and a maximum of 25 at a time. For larger groups, we organize the tour in 15-minute intervals.
                                    </p>

                                    <p className="description" style={{fontSize: "0.925rem"}}>
                                        Sitarjevec snack and Dwarf's šmorn are organized for groups of at least 10 visitors.
                                    </p>
                                </div>

                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Services</th>
                                            <th scope="col">Individuals</th>
                                            <th scope="col">Groups</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transportRowPrices.map((priceItem, index) => (
                                            <PriceItem priceItem={priceItem} numOfColumns={3} key={'transport-' + index} />
                                        ))}
                                    </tbody>
                                </table>

                                <h4 className="mt--60 mb--0">Main Pit</h4>

                                <div className="section-title mt--0 mb--30">
                                    <p className="description" style={{fontSize: "0.925rem", marginBottom: 0}}>
                                        The tour is free for tour guides, journalists and accompanying groups. For children up to 10 years and viewing is not possible for the physically challenged.
                                    </p>

                                    <p className="description" style={{fontSize: "0.925rem", marginBottom: 0}}>
                                        We organize tours for a minimum of 4 people and a maximum of 10 people at the same time. For
                                        larger groups, visit organized in 15-minute intervals.
                                    </p>

                                    <p className="description" style={{fontSize: "0.925rem"}}>
                                        Sitarjevec snack and Dwarf's šmorn are organized for groups of at least 10 visitors.
                                    </p>
                                </div>

                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th width="60%" scope="col">Services</th>
                                        <th scope="col">Groups</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {mainRowPrices.map((priceItem, index) => (
                                            <PriceItem numOfColumns={2} priceItem={priceItem} key={'main-' + index}/>
                                        ))}
                                    </tbody>
                                </table>

                                {/*new service*/}
                                <div className="section-title mt--120 mb--30">
                                    <h3 className="title">Combined tour of the Export and Main pit</h3>

                                    <p className="description" style={{fontSize: "0.925rem", marginBottom: 0}}>
                                        For tour guides, journalists and group companions, the visit is free. For children up to 10 years and physically challenged, the visit is not possible.
                                    </p>

                                    <p className="description" style={{fontSize: "0.925rem", marginBottom: 0}}>
                                        We organize tours for a minimum of 4 people and a maximum of 10 people at the same time. For
                                        larger groups, visit organized in 15-minute intervals.
                                    </p>

                                    <p className="description" style={{fontSize: "0.925rem"}}>
                                        Sitarjevec snack and Dwarf's šmorn are organized for groups of at least 10 visitors.
                                    </p>
                                </div>

                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th width="60%" scope="col">Service</th>
                                        <th scope="col">Groups</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        { combinationOfRowsPricing.map((priceItem, index) => (
                                            <PriceItem priceItem={priceItem} numOfColumns={2} key={'combination-' + index} />
                                        )) }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4">
                            <ReservationScheduleBox/>
                            <OpenHoursBox/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesPricing;
