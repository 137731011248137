import React from 'react';

function OpenHoursBox(props) {
    return (
        <div className="rn-address bg_color--3">
            <div className="inner d-flex justify-content-center align-items-center flex-column">

                <h4 className="title text-center d-inline-block mt--15">Schedule of tours of Sitarjevec Litija Mine</h4>
                <p className="mt--15 text-center">Tours are available all days of the week by prior arrangement for a minimum of 4 visitors.</p>

                <small className="text-center mt--30 saturday-special-offer"><strong>It is possible to visit the Export Pit of the mine without prior notice every Saturday at 10:00 AM.</strong></small>

                <small className="text-center mt--30">Required prior order.</small>
            </div>
        </div>
    );
}

export default OpenHoursBox;